/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
    font-family: "Montserrat";
    font-weight: 100;
    font-style: normal;
    src: url("Montserrat-Thin.ttf");
}

/** Montserrat Thin-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 100;
    font-style: italic;
    src: url("Montserrat-ThinItalic.ttf");
}

/** Montserrat ExtraLight **/
@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    font-style: normal;
    src: url("Montserrat-ExtraLight.ttf");
}

/** Montserrat ExtraLight-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    font-style: italic;
    src: url("Montserrat-ExtraLightItalic.ttf");
}

/** Montserrat Light **/
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: normal;
    src: url("Montserrat-Light.ttf");
}

/** Montserrat Light-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: italic;
    src: url("Montserrat-LightItalic.ttf");
}

/** Montserrat Regular **/
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    src: url("Montserrat-Regular.ttf");
}

/** Montserrat Regular-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: italic;
    src: url("Montserrat-Italic.ttf");
}

/** Montserrat Medium **/
@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    font-style: normal;
    src: url("Montserrat-Medium.ttf");
}

/** Montserrat Medium-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    font-style: italic;
    src: url("Montserrat-MediumItalic.ttf");
}

/** Montserrat SemiBold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: normal;
    src: url("Montserrat-SemiBold.ttf");
}

/** Montserrat SemiBold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: italic;
    src: url("Montserrat-SemiBoldItalic.ttf");
}

/** Montserrat Bold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: normal;
    src: url("Montserrat-Bold.ttf");
}

/** Montserrat Bold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: italic;
    src: url("Montserrat-BoldItalic.ttf");
}

/** Montserrat ExtraBold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: normal;
    src: url("Montserrat-ExtraBold.ttf");
}

/** Montserrat ExtraBold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: italic;
    src: url("Montserrat-ExtraBoldItalic.ttf");
}

/** Montserrat Black **/
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: normal;
    src: url("Montserrat-Black.ttf");
}

/** Montserrat Black-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: italic;
    src: url("Montserrat-BlackItalic.ttf");
}
